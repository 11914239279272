<template>
  <div class="py-4 container mx-auto">
    <!-- Kanban Board Section -->
    <div class="kanban-grid">
      <div 
        v-for="board in boards" 
        :key="board.id" 
        :class="['kanban-column', { 'expanded-kanban-column': isExpanded(board.id) }]">
        
        <div class="bg-white rounded shadow-lg p-4 border border-gray-200">
          <div class="flex items-center justify-between">
  <h2 class="text-lg font-semibold text-gray-700">{{ board.title }}</h2>
  <span class="cursor-pointer text-gray-500 ml-2" @click="toggleExpand(board.id)">
    <span v-if="!isExpanded(board.id)">&#x25BC;</span> <!-- Down arrow -->
    <span v-else>&#x25B2;</span> <!-- Up arrow -->
  </span>
</div>

<br>
          <!-- Scrollable Container (expands or collapses) -->
          <div class="kanban-scrollable" :class="{ 'expanded': isExpanded(board.id) }">
            <draggable
              v-model="board.items"
              group="tasks"
              @end="onEnd"
              item-key="id"
              class="p-2 bg-gray-50 rounded"
            >
              <template #item="{ element }">
                <div
                  class="bg-white p-2 rounded shadow-sm mb-2 cursor-move text-gray-600 flex justify-between items-center"
                >
                  <span
                    class="flex-grow truncate"
                    @click="openEditTaskModal($event, element.salesObjectId, board.id)"
                  >
                    {{ element.title }} - {{ element.date }}
                  </span>
                  <!-- Green Check Icon for Sent Status -->
                  <span
                    v-if="element.sent"
                    class="sent-icon cursor-pointer ml-2 text-green-500"
                  >
                    &#x2714; Sent
                  </span>
                  <!-- Blue Check Icon for Confirmation (only if not sent) -->
                  <span
                    v-else-if="element.confirmed"
                    class="check-icon cursor-pointer ml-2 text-blue-500"
                    @click.stop="toggleConfirmed(element)"
                  >
                    &#x2713;
                  </span>
                  <!-- Gray Check Icon when neither sent nor confirmed -->
                  <span
                    v-else
                    class="check-icon cursor-pointer ml-2 text-gray-400"
                    @click.stop="toggleConfirmed(element)"
                  >
                    &#x2713;
                  </span>
                </div>
              </template>
            </draggable>
          </div>

          <!-- Add Task Button -->
          <div
            @click="openNewTaskModal($event, board.id, board.title, board.items[0]?.date || '')"
            class="add-task-icon text-blue-600 hover:text-blue-800 cursor-pointer"
          >
            &#x2b;
          </div>
        </div>
      </div>
    </div>

    <!-- Decision Maker(s) Section -->
    <div class="decision-makers-section my-4 p-4 bg-white rounded shadow-md border border-gray-200">
      <h3 class="text-lg font-semibold text-gray-700 mb-4">Decision Maker(s)</h3>

      <!-- Inputs and remove button in a single row -->
      <div v-for="(decisionMaker, index) in decisionMakers" :key="index" class="flex items-center mb-2 justify-between">
        <input type="text" v-model="decisionMaker.first_name" @input="onFieldInput(index)" @blur="onFieldBlur(index)" class="decision-maker-input" placeholder="First Name">
        <input type="text" v-model="decisionMaker.last_name" @input="onFieldInput(index)" @blur="onFieldBlur(index)" class="decision-maker-input" placeholder="Last Name">
        <input type="email" v-model="decisionMaker.email" @input="onFieldInput(index)" @blur="onFieldBlur(index)" class="decision-maker-input" placeholder="Email">
        <input type="text" v-model="decisionMaker.role" @input="onFieldInput(index)" @blur="onFieldBlur(index)" class="decision-maker-input" placeholder="Role">
        
        <!-- Dropdowns for decision making types -->
        <select v-model="decisionMaker.primary_decision_making_type" @change="onFieldChange(index)" class="decision-maker-select">
          <option disabled value="">Primary Decision Making Type</option>
          <option v-for="type in primaryTypes" :key="type" :value="type">{{ type }}</option>
        </select>
        <select v-model="decisionMaker.secondary_decision_making_type" @change="onFieldChange(index)" class="decision-maker-select">
          <option disabled value="">Secondary Decision Making Type</option>
          <option v-for="type in secondaryTypes" :key="type" :value="type">{{ type }}</option>
        </select>

        <button @click="removeDecisionMaker(index)" class="remove-button text-red-500">
          ✖
        </button>
      </div>

      <button @click="addDecisionMaker" class="add-decision-maker-button">+ Add Decision Maker</button>
    </div>

    <!-- Calendar Section -->
    <div class="mt-4">
      <calendar :key="calendarKey" :events="calendarEvents" id="calendar-table" />
    </div>

    <dynamic-modal
      :isVisible="showNewTaskModal"
      modalType="task"
      :modalPosition="modalPosition"
      :categoryName="selectedCategoryName"
      :defaultDate="selectedEventDate"
      :proposalId="proposalId"
      @close="closeNewTaskModal"
      @taskAdded="handleTaskAdded"
      @refreshTasks="fetchCalendarEvents"
    />

    <dynamic-modal
      ref="dynamicModal"
      :isVisible="showEditTaskModal"
      modalType="edit-task"
      :modalPosition="modalPosition"
      :categoryName="selectedCategoryName"
      :defaultDate="selectedTaskDate"
      :task="selectedTask"
      :proposalId="proposalId"
      @close="closeEditTaskModal"
      @delete="deleteTask"
      @refreshTasks="fetchCalendarEvents"
    />
  </div>
</template>



<script>
import axios from 'axios';
import draggable from 'vuedraggable';
import Calendar from "../../examples/Calendar.vue";
import DynamicModal from "@/components/DynamicModal.vue"; // Import DynamicModal component

export default {
  name: "KanbanCalendarPage",
  components: { draggable, Calendar, DynamicModal }, // Register DynamicModal
  data() {
    return {
      isSavingDecisionMaker: false,
      expandedBoards: [], // Track expanded boards
      proposalId: this.$route.params.accountId,
      calendarEvents: [],
      boards: [],
      decisionMakers: [], // Added for managing decision makers
      originalDecisionMakers: [], // Added to keep track of the original data for comparison
      showNewTaskModal: false,
      showEditTaskModal: false, // Added for editing task modal visibility
      newTask: { title: "", date: "", description: "" },
      selectedTask: null, // Changed from currentTask to selectedTask for clarity
      calendarKey: 0,
      modalPosition: { x: 0, y: 0 }, // Added for modal position
      selectedCategoryName: '', // Added to store the selected category name
      selectedEventDate: '', // Added to store the selected event date
      selectedTaskDate: '', // Added to store the selected task date for editing
      typingTimeout: null, // Timeout ID for debouncing
      primaryTypes: [], // List for primary decision-making types
      secondaryTypes: [] // List for secondary decision-making types
    };
  },

  async mounted() {
    await this.fetchCalendarEvents();
    await this.fetchDecisionMakers(); // Load decision makers on page load
    this.initializeKanban();
    this.loadDropdownOptions(); // Load dropdown options on page load
  },
  methods: {
    toggleExpand(boardId) {
      console.log('Toggling board with ID:', boardId); // Added console log for debugging
      if (this.expandedBoards.includes(boardId)) {
        this.expandedBoards = this.expandedBoards.filter(id => id !== boardId);
      } else {
        this.expandedBoards.push(boardId);
      }
      console.log('Current expanded boards:', this.expandedBoards); // Track current state
    },
    isExpanded(boardId) {
      return this.expandedBoards.includes(boardId);
    },
    async fetchDecisionMakers() {
  const token = localStorage.getItem('access_token');
  if (token) {
    try {
      const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/decision-makers/${this.proposalId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      this.decisionMakers = [];

      // Map over decision makers to ensure empty string for null types
      this.decisionMakers = response.data.decision_makers.map(maker => ({
        ...maker,
        primary_decision_making_type: maker.primary_decision_making_type || '', // Set to "" if null
        secondary_decision_making_type: maker.secondary_decision_making_type || '' // Set to "" if null
      }));

      // Deep copy to track changes
      this.originalDecisionMakers = JSON.parse(JSON.stringify(this.decisionMakers));

      console.log('Decision makers loaded successfully:', this.decisionMakers);
    } catch (error) {
      console.error('Failed to fetch decision makers:', error);
    }
  } else {
    console.error("No access token found");
    alert("No access token found. Please log in.");
  }
},


    loadDropdownOptions() {
      // Load dropdown options (you can fetch them from an API or define them here)
      this.primaryTypes = ["Dominance", "Influence", "Steadiness", "Conscientiousness"];
      this.secondaryTypes = ["Dominance", "Influence", "Steadiness", "Conscientiousness"];
    },

    hasChanges(index) {
      return JSON.stringify(this.decisionMakers[index]) !== JSON.stringify(this.originalDecisionMakers[index]);
    },

    // Save or update decision maker
    async saveDecisionMaker(index) {
      const decisionMaker = this.decisionMakers[index];

      // If no changes or the decision maker is already marked as created, return early
      //if (!this.hasChanges(index) || decisionMaker.created) return;
      if (!this.hasChanges(index)) return;

      const token = localStorage.getItem('access_token');

      try {
        let response;

        this.isSavingDecisionMaker = true;

        if (decisionMaker.id) {
          // If an ID exists, it's an update, apply 2-second debounce for update
          response = await axios.post(`${process.env.VUE_APP_API_BASE_URL}/decision-maker/update/${decisionMaker.id}`, decisionMaker, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          console.log('Decision maker updated successfully', response.data);
        } else if (!decisionMaker.created) {
          // Create the decision maker (only if it has not been created yet)
          response = await axios.post(`${process.env.VUE_APP_API_BASE_URL}/decision-maker/create/${this.proposalId}`, decisionMaker, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });

          // After successful creation, mark this decision maker as created
          this.decisionMakers[index] = { ...decisionMaker, id: response.data.decision_maker.id, created: true };
          console.log('Decision maker created successfully', response.data);

          this.isSavingDecisionMaker = false;
          // Reload decision makers only after successful creation
          await this.fetchDecisionMakers();
        }

        // Update the original data to reflect the saved state
        this.originalDecisionMakers[index] = JSON.parse(JSON.stringify(this.decisionMakers[index]));
      } catch (error) {
        console.error('Failed to save decision maker', error);
      }
    },

    // Triggered when a user types into a field (apply debounce for existing decision makers)
    onFieldInput(index) {
      const decisionMaker = this.decisionMakers[index];
      if (decisionMaker.id) {
        // Only apply debounce if updating an existing decision maker
        clearTimeout(this.typingTimeout);
        this.typingTimeout = setTimeout(() => {
          if (this.hasChanges(index)) {
            this.saveDecisionMaker(index);  // Debounced save for existing decision makers
          }
        }, 1000); // 2-second debounce timer
      }
    },

    // Triggered when the field loses focus (immediate save for new decision makers)
    onFieldBlur(index) {
      const decisionMaker = this.decisionMakers[index];
      if (decisionMaker.id || !this.isSavingDecisionMaker) {
        // Immediate save for new decision makers when field loses focus
        if (this.hasChanges(index)) {
          this.saveDecisionMaker(index);
        }
      }
    },

    // Triggered immediately when a dropdown value changes (immediate save for new decision makers)
    onFieldChange(index) {
      const decisionMaker = this.decisionMakers[index];
      if (decisionMaker.id && decisionMaker.created) {
        // Immediate save for new decision makers when dropdown changes
        if (this.hasChanges(index)) {
          this.saveDecisionMaker(index);
        }
      }
    },


    handleTaskAdded(task) {
      console.log('Task Added:', task);
      if (!this.decisionMakers.length) {
        alert('Please add at least one decision maker before adding tasks.');
        return;
      }
      const board = this.boards.find(board => board.id === this.selectedCategoryName.toLowerCase().replace(/ /g, "_"));
      if (board) {
        board.items.push({
          id: task.scheduled_sales_object_id, // Use scheduled_sales_object_id
          title: task.name,
          date: task.date,
          salesObjectId: task.scheduled_sales_object_id, // Ensure the ID is added for future operations
          confirmed: false, // Default confirmed status to false
        });
      }
    },

    async fetchCalendarEvents() {
      this.proposalId = this.$route.params.accountId;
      const token = localStorage.getItem('access_token');
      if (token) {
        try {
          const accountId = this.$route.params.accountId;
          const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/calendar-new/${accountId}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          const eventsData = response.data.original;
          this.calendarEvents = this.transformEvents(eventsData); // Set transformed events for calendar
          this.boards = this.initializeKanban(eventsData); // Initialize boards for Kanban
          this.calendarKey += 1; // Update the calendar key to refresh the calendar
        } catch (error) {
          console.error("Failed to fetch calendar events", error);
        }
      } else {
        console.error("No access token found");
        alert("No access token found. Please log in.");
      }
    },

    transformEvents(events) {
      return events.flatMap(category => {
        return (category.events || []).map(event => {
          const dateOnly = event.scheduled_date.split('T')[0];
          return {
            title: event.sales_object_name || category.category,
            start: dateOnly,
            end: dateOnly,
            category: category.category,
            name: event.sales_object_name || 'Unnamed Event',
          };
        });
      });
    },

    initializeKanban(events) {
  if (!events || events.length === 0) {
    console.warn("No events available to initialize Kanban.");
    return [];
  }

  console.log("Events to initialize Kanban:", events); // Log the entire events array

  return events.map(category => ({
    id: category.category.toLowerCase().replace(/ /g, "_"),
    title: category.category,
    items: (category.events || []).map(event => {
      console.log("Processing event:", event); // Log each individual event object

      return {
        id: event.scheduled_sales_object_id, // Use scheduled_sales_object_id as unique identifier
        title: event.sales_object_name || category.category,
        date: event.scheduled_date.split('T')[0], // Extract the date part
        salesObjectId: event.scheduled_sales_object_id, // Add scheduled_sales_object_id to each item
        confirmed: event.confirmed || false, // Add the confirmed status from backend data
        type: event.type || 'Uncategorized', // Log the type of event
        sent: event.sent || false,
        decision_maker_name: event.decision_maker_name || "N/A", // Log decision maker if available
        decision_maker_id: event.decision_maker_id || null, // Log decision maker ID if available
        download_link: event.download_link || null, // Log download link if available
      };
    }),
  }));
},


    openNewTaskModal(event, boardId, categoryName, eventDate) {
      if (!this.decisionMakers.length) {
        alert('Please add at least one decision maker before adding tasks.');
        return;
      }
      this.proposalId = this.$route.params.accountId;
      const buttonRect = event.target.getBoundingClientRect();
      this.modalPosition = {
        x: buttonRect.left + window.scrollX + buttonRect.width / 2,
        y: buttonRect.top + window.scrollY + buttonRect.height / 2
      };
      this.newTask.boardId = boardId;
      this.selectedCategoryName = categoryName; // Set the selected category name
      this.selectedEventDate = eventDate; // Set the selected event date
      this.showNewTaskModal = true;
    },

    closeNewTaskModal() {
      this.showNewTaskModal = false;
      this.newTask = { title: "", date: "", description: "" };
    },

    addNewTask() {
      if (!this.decisionMakers.length) {
        alert('Please add at least one decision maker before adding tasks.');
        return;
      }
      if (this.newTask.title && this.newTask.date) {
        const board = this.boards.find(board => board.id === this.newTask.boardId);
        const newTaskId = `_${this.newTask.title.toLowerCase().replace(/ /g, "_")}${board.id}`;
        board.items.push({
          id: newTaskId,
          title: this.newTask.title,
          date: this.newTask.date,
        });
        this.closeNewTaskModal();
      }
    },

    addDecisionMaker() {
      this.decisionMakers.push({
        first_name: '',
        last_name: '',
        email: '',
        role: '',
        primary_decision_making_type: '',
        secondary_decision_making_type: ''
      });
    },
/*
    removeDecisionMaker(index) {
      this.decisionMakers.splice(index, 1);
    },
*/
async removeDecisionMaker(index) {
      const decisionMaker = this.decisionMakers[index];
      const token = localStorage.getItem('access_token');

      // Confirmation prompt before deletion
      const confirmed = window.confirm(`Are you sure you want to delete the decision maker ${decisionMaker.first_name} ${decisionMaker.last_name}?`);

      if (!confirmed) return; // If the user cancels, exit the function

      try {
        if (decisionMaker.id) {
          await axios.delete(`${process.env.VUE_APP_API_BASE_URL}/decision-makers/delete/${decisionMaker.id}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });

          console.log('Decision maker deleted successfully');

          // Remove the deleted decision maker from the list
          this.decisionMakers.splice(index, 1);
          this.originalDecisionMakers.splice(index, 1);
        }
      } catch (error) {
        console.error('Failed to delete decision maker', error);
      }
    },

    openEditTaskModal(event, taskId, boardId) {
      const board = this.boards.find(board => board.id === boardId);
      if (board) {
        const taskData = board.items.find(task => task.salesObjectId === taskId);
        if (taskData) {
          const taskRect = event.target.getBoundingClientRect();
          this.modalPosition = {
            x: taskRect.left + window.scrollX + taskRect.width / 2,
            y: taskRect.top + window.scrollY + taskRect.height / 2
          };
          this.selectedTask = taskData; // Set the selected task data
          console.log("Selected Task: ", this.selectedTask);
          this.selectedTaskDate = taskData.date; // Set the selected task date
          this.selectedCategoryName = boardId; // Set the selected category name for context
          this.showEditTaskModal = true;
        } else {
          console.error("Task data not found");
        }
      } else {
        console.error("Board not found");
      }
    },

    closeEditTaskModal() {
      this.showEditTaskModal = false;
      this.selectedTask = null;
    },

    deleteTask(taskId) {
      this.boards = this.boards.map(board => ({
        ...board,
        items: board.items.filter(item => item.salesObjectId !== taskId) // Use salesObjectId for deletion
      }));
      this.closeEditTaskModal();
    },
    toggleConfirmed(task) {
  // Set selected task and show modal
  this.selectedTask = task;
  this.modalPosition = { x: window.innerWidth / 2, y: window.innerHeight / 2 }; // Center modal on screen
  this.showEditTaskModal = true; // Open the modal

  // Use $nextTick to ensure DOM updates
  this.$nextTick(() => {
    const dynamicModal = this.$refs.dynamicModal;
    if (dynamicModal) {
      dynamicModal.selectedTask = task; // Set task data
      dynamicModal.step = 5; // Directly set step to preview
      dynamicModal.showTaskPreview(task.salesObjectId, task.content || 'Default content', 1); // Open preview
    } else {
      console.error("DynamicModal ref is undefined");
    }
  });
},


/*
    toggleConfirmed(task) {
      task.confirmed = !task.confirmed; // Toggle the confirmed status
      const token = localStorage.getItem('access_token');
      axios.put(`${process.env.VUE_APP_API_BASE_URL}/schedule/${task.salesObjectId}/confirm`, {
        confirmed: task.confirmed
      }, {
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      })
      .then(() => {
        console.log('Task confirmation status updated successfully.');
        this.fetchCalendarEvents();
      })
      .catch(error => {
        console.error('Error updating task confirmation status:', error);
        task.confirmed = !task.confirmed; // Revert the change if there's an error
      });
    },
*/
    onEnd(event) {
      console.log("Drag ended", event);
    }
  },
};
</script>




<style scoped>
.kanban-grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(9, auto);
  gap: 10px;
  justify-items: center;
}

.kanban-column {
  display: flex;
  flex-direction: column;
  background-color: #f8f9fa;
  border: 1px solid #ddd;
  border-radius: 0.75rem;
  padding: 5px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
  max-height: 85px;
  width: 50%;
  overflow: hidden;
  transition: max-height 0.3s ease;
}

.kanban-column.expanded-kanban-column {
  max-height: 500px; /* Adjust based on the expected expanded height */
}

.kanban-scrollable {
  overflow-y: auto;
  max-height: 80px;
  transition: max-height 0.3s ease;
}

.kanban-scrollable.expanded {
  max-height: 300px; /* Adjust as necessary */
}

.add-task-icon {
  font-size: 18px;
  text-align: center;
  margin-top: 5px;
}

.bg-white {
  background-color: #fff;
}

.text-gray-600 {
  color: #718096;
}

.text-blue-600:hover {
  color: #2b6cb0;
}

.p-2 {
  padding: 0.5rem;
}

.rounded {
  border-radius: 0.375rem;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175);
}

.check-icon {
  font-size: 18px;
  cursor: pointer;
  transition: color 0.3s;
}

.text-blue-500 {
  color: #4299e1;
}

.text-gray-400 {
  color: #cbd5e0;
}

.expanded-content {
  padding: 10px;
  background-color: #f1f1f1;
  border-radius: 0.375rem;
  margin-top: 10px;
}

.collapse-btn {
  display: block;
  text-align: center;
  font-size: 16px;
  cursor: pointer;
}

.decision-makers-section {
  background-color: #f8f9fa;
  border: 1px solid #ddd;
  border-radius: 0.75rem;
  padding: 10px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
}

.decision-maker-input {
  flex: 1;
  max-width: 15%;
  margin-right: 10px;
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 0.375rem;
  box-sizing: border-box;
}

.decision-maker-select {
  flex: 1;
  max-width: 15%;
  margin-right: 10px;
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 0.375rem;
  box-sizing: border-box;
}

.remove-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 1.2rem;
  line-height: 1;
  margin-left: 10px;
}

.add-decision-maker-button {
  background-color: #4299e1;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 0.375rem;
  cursor: pointer;
  transition: background-color 0.3s;
  border: none;
}

.add-decision-maker-button:hover {
  background-color: #2b6cb0;
}
.flex {
  display: flex;
  align-items: center;
}

.justify-between {
  justify-content: space-between;
}

.ml-2 {
  margin-left: 8px;
}
</style>
