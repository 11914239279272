<template>
  <div v-if="isVisible" class="modal-backdrop" :style="modalStyle">
    <!-- Close Button -->
    <button class="close-button" @click="closeModal">×</button>

    <!-- Modal Content -->
    <div class="modal-content bg-white rounded shadow-lg p-4 border border-gray-200" :style="{ paddingRight: step === 2 || step === 5 ? '20px' : '15px' }">
      <!-- Fixed Title Area -->
      <div class="fixed-header">
        <h4 v-if="modalTitle" class="text-lg font-semibold text-gray-700 mb-4">{{ modalTitle }}</h4>
      </div>

      <!-- Modal Scrollable Area -->
      <div class="modal-scrollable-content">
        <!-- Event List Screen -->
        <template v-if="modalType === 'event' && step === 1">
          <ul class="event-list">
            <li v-for="(event, index) in modalEvents" :key="index">
              {{ event.title }}
              <a href="javascript:;" @click="openProposal(event.proposalId)" class="event-link">Open</a>
            </li>
          </ul>
        </template>

        <!-- Task Selection Screen -->
        <template v-if="modalType === 'task' && step === 1">
          <p><strong>Category:</strong> {{ categoryName }}</p>
          <ul class="task-list">
            <li v-for="(task, index) in tasks" :key="index" class="task-item">
              <span>
                {{ task.name }}<span @click="showTaskPreview(task.id, task.content || 'Hello World! This is a default email content.', 'task', 2)" class="icon-preview"> ?</span>
              </span>
              <span @click="selectTask(task)" class="icon-assign">+</span>
            </li>
          </ul>
        </template>

        <!-- Task Details Screen -->
        <template v-if="modalType === 'task' && step === 2">
          <div>
            <p><strong>Task:</strong> {{ selectedTask.name }}</p>

            <!-- Recipient Dropdown to Edit Decision Maker -->
            <label for="decision-maker" class="mt-2">Recipient:</label>
            <select id="decision-maker" v-model="selectedDecisionMakerId" @change="updateDecisionMakerDetails" class="form-control mb-4">
              <option disabled value="">Select a Recipient</option>
              <option v-for="(decisionMaker, index) in decisionMakers" :key="index" :value="decisionMaker.id">
                {{ decisionMaker.first_name }} {{ decisionMaker.last_name }}
              </option>
            </select>

            <label for="task-date-picker" class="mt-2">Edit Date:</label>
            <input 
              id="task-date-picker" 
              type="text" 
              class="form-control mb-2" 
              v-model="selectedTaskDate" 
              @focus="showDatePicker('task-date-picker')" 
              readonly
            />
            <a href="javascript:;" @click="showTaskPreview(selectedTask.id, selectedTask.content || 'Hello World! This is a default email content.', 'task', 2)" class="text-blue-600 hover:text-blue-800">Preview</a>
          </div>
        </template>

        <!-- Edit Task Screen -->
        <template v-if="modalType === 'edit-task' && step === 1">
          <div>
            <p>
              <strong>Task:</strong> {{ selectedTask.title }}
              <span 
                class="icon-preview text-blue-600 hover:text-blue-800 cursor-pointer ml-2"
                @click="showTaskPreview(selectedTask.id, selectedTask.content || 'Hello World! This is a default email content.', 'edit-task', 1)"
                title="Preview"
              >
                ?
              </span>
            </p>

            <!-- Recipient Dropdown for Editing -->
            <label for="decision-maker-edit" class="mt-2">Recipient:</label>
            <select id="decision-maker-edit" v-model="selectedDecisionMakerId" @change="updateDecisionMakerDetails" class="form-control mb-4">
              <option disabled value="">Select a Recipient</option>
              <option v-for="(decisionMaker, index) in decisionMakers" :key="index" :value="decisionMaker.id">
                {{ decisionMaker.first_name }} {{ decisionMaker.last_name }}
              </option>
            </select>

            <label for="task-date-edit-picker" class="mt-2">Edit Date:</label>
            <input 
              id="task-date-edit-picker" 
              type="text" 
              class="form-control mb-2" 
              v-model="selectedTaskDate" 
              @focus="showDatePicker('task-date-edit-picker')" 
              readonly
            />

            <!-- Checkbox for Confirmed Status -->
            <label class="mt-2 flex items-center">
              <input 
                type="checkbox" 
                v-model="taskConfirmed" 
                @change="updateConfirmedStatus(taskConfirmed)"
                class="mr-2"
              />
              Confirmed
            </label>
          </div>
        </template>
        <template v-if="step === 5">
  <!-- Show the loading spinner while the iframe content is loading -->
  <div v-if="loading" class="loading-container">
    <div class="spinner"></div>
    <p>Loading content, please wait...</p>
  </div>

  <!-- PDF or DOCX Preview -->
  <div v-show="!loading && (previewType === 'pdf' || previewType === 'docx')">
    <!-- PDF Preview -->
    <iframe 
      v-if="previewType === 'pdf'" 
      :src="previewContent" 
      width="100%" 
      height="850px" 
      style="border: none;"
      @load="onIframeLoad"
    ></iframe>

    <!-- DOCX Preview using Office online viewer -->
    <iframe 
      v-else-if="previewType === 'docx'" 
      :src="`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(previewContent)}`" 
      width="100%" 
      height="850px"
      style="border: none;"
      @load="onIframeLoad"
    ></iframe>
  </div>

  <!-- Email Template Preview -->
  <div v-if="!loading && previewType === 'email' && selectedTask && selectedTask.type === 'email_template'" style="height: 100%;">
    <div class="object-info mb-3">
      <h3 class="text-lg font-semibold">{{ selectedTask.title }}</h3>
      <p class="text-sm text-gray-600">Recipient: {{ selectedTask.decision_maker_name || 'No Recipient' }}</p>
    </div>

    <label for="subject" class="text-sm text-gray-600">Subject:</label>
    <input type="text" id="subject" v-model="subject" class="form-control mb-2" />

    <div contenteditable="true"
         @blur="updatePreviewContentOnBlur"
         @keydown="handleKeyDown"
         v-html="formattedPreviewContent"
         class="border border-gray-300 p-2 rounded"
         style="min-height: 150px; overflow-y: auto;">
    </div>
  </div>

  <!-- No Preview Available -->
  <!--div v-else>
    <p>Preview not available for this document type. Please download to view.</p>
    <a :href="previewContent" class="btn btn-primary" download>Download Document</a>
  </div-->
</template>



        <!-- Email Preview Screen (Step === 6) -->
<template v-if="step === 6">
  <!-- Object Information Display -->
  <div class="object-info mb-3">
    <h3 class="text-lg font-semibold">Email Preview/Edit</h3>
    <p class="text-sm text-gray-600">Recipient: {{ selectedDecisionMakerName || 'No Recipient' }}</p>
  </div>

  <!-- Subject Input -->
  <label for="subject" class="text-sm text-gray-600">Subject:</label>
  <input type="text" id="subject" v-model="subject" class="form-control mb-2" />

  <!-- ContentEditable Div for Rich Text -->
  <div contenteditable="true"
       @blur="updatePreviewContentOnBlur"
       v-html="formattedPreviewContent"
       class="border border-gray-300 p-2 rounded"
       style="min-height: 150px; overflow-y: auto;">
  </div>
</template>


        <!-- Date Picker Screen for Edit Task -->
        <template v-if="modalType === 'edit-task' && step === 3">
          <custom-date-picker
            v-model="selectedTaskDate"
            @input="handleDateSelect"
            :value="selectedTaskDate"
            class="form-control mb-2 date-picker-container"
          />
        </template>

        <!-- Date Picker Screen -->
        <template v-if="modalType === 'task' && step === 4">
          <custom-date-picker
            v-model="selectedTaskDate"
            @input="handleDateSelect"
            :value="selectedTaskDate"
            class="form-control mb-2 date-picker-container"
          />
        </template>
      </div>

<!-- Fixed Footer Area -->
<div class="fixed-footer flex justify-end mt-4">
  <!-- Show Delete button only on the root edit task modal (modalType === 'edit-task' and step === 1) -->
  <button v-if="modalType === 'edit-task' && step === 1" @click="deleteScheduledSalesObject" class="mr-2 btn btn-danger">Delete</button>

  <!-- Show Back button on all screens except the root edit task modal -->
  <button v-else @click="goBackToPreviousStep" class="mr-2 btn btn-secondary">Back</button>

  <!-- Use the same handleSaveOrAdd button for both document and email preview steps -->
  <button @click="handleSaveOrAdd" class="btn btn-primary">
    {{ 
      // If previewing PDF/DOCX, show "Next" for navigation to email preview
      step === 5 && (previewType === 'pdf' || previewType === 'docx') ? 'Next' :
      // If on email preview step, show "Save" for saving email
      step === 6 ? 'Save' : 
      // Default button behavior for other steps
      (modalType === 'edit-task' ? 'Save' : 'Add') 
    }}
  </button>
</div>

    </div>
  </div>
</template>

<script>
import axios from 'axios';
import CustomDatePicker from './CustomDatePicker.vue';

export default {
  props: {
    proposalId: {
      type: Number,
      default: 0,
    },
    modalType: {
      type: String,
      default: 'event',
    },
    modalEvents: {
      type: Array,
      default: () => [],
    },
    modalTitle: {
      type: String,
      default: '',
    },
    modalDate: {
      type: String,
      default: '',
    },
    modalPosition: {
      type: Object,
      default: () => ({ x: 0, y: 0 }),
    },
    isVisible: {
      type: Boolean,
      default: false,
    },
    categoryName: {
      type: String,
      default: '',
    },
    defaultDate: {
      type: String,
      default: ''
    },
    task: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    CustomDatePicker,
  },
  data() {
    return {
      loading: true,
      tasks: [],
      fullTaskData: [],
      step: 1,
      selectedTask: this.task || null,
      selectedTaskDate: this.defaultDate || '',
      previewContent: '',
      subject: '',  // New data property for the subject
      previewType: 'email',  // Default to email preview
      editorInit: {
        height: 300,
        menubar: false,
        plugins: '',
        toolbar: 'undo redo | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat'
      },
      localModalType: this.modalType,
      savedState: {},  // Object to save the state before preview
      previousStep: null,
      previousModalType: null,
      decisionMakers: [],
      selectedDecisionMakerId: '', // Initialize as empty string
      selectedDecisionMakerName: '',
    };
  },
  computed: {
    formattedPreviewContent() {
      return this.previewContent.replace(/\n/g, '<br>');
    },
    taskConfirmed: {
      get() {
        return this.selectedTask && this.selectedTask.confirmed === 1;
      },
      set(value) {
        if (this.selectedTask) {
          this.selectedTask.confirmed = value ? 1 : 0;
        }
      }
    },
    modalStyle() {
      let modalWidth = 350;
      let modalHeight = 350;

      if (this.step === 5 || this.step === 6) {
        modalWidth = 850;
        modalHeight = 700;
        const top = (window.innerHeight - modalHeight) / 2;
        const left = (window.innerWidth - modalWidth) / 2.5;

        return {
          top: `${top}px`,
          left: `${left}px`,
          width: `${modalWidth}px`,
          height: `${modalHeight}px`,
          position: 'absolute',
        };
      }

      const top = this.modalPosition.y - modalHeight / 2;
      const left = this.modalPosition.x - modalWidth / 0.72;

      return {
        top: `${top}px`,
        left: `${left}px`,
        width: `${modalWidth}px`,
        height: `${modalHeight}px`,
        position: 'absolute',
      };
    },
  },
  watch: {
    isVisible(newVal) {
      if (newVal) {
        if (this.localModalType === 'edit-task') {
          this.selectedTask = this.task || null;
          this.selectedTaskDate = this.modalDate || this.defaultDate;
          this.fetchDecisionMakers();
          this.step = 1;

          // Automatically go to preview if step is set for preview
          if (this.step === 5) {
            this.showTaskPreview(this.selectedTask.salesObjectId, this.selectedTask.content || 'Default content', 1);
          }
        } else if (this.localModalType === 'task') {
          this.fetchTasks();
          this.fetchDecisionMakers();
          this.selectedTaskDate = this.defaultDate;
        }
      }
    },
    defaultDate(newVal) {
      this.selectedTaskDate = newVal;
    }
  },
  methods: {
    isPdfFile(url) {
      return url.toLowerCase().endsWith('.pdf');
    },
    
    isDocxFile(url) {
      return url.toLowerCase().endsWith('.docx');
    },

    updatePreviewContentOnBlur(event) {
      this.previewContent = event.target.innerText; // Use innerText to get the plain text content
    },
    handleKeyDown(event) {
      if (event.key === 'Enter') {
        event.preventDefault(); // Prevent default behavior of Enter key to avoid extra new lines
      }
    },
    fetchTasks() {
      const token = localStorage.getItem('access_token');
      if (token) {
        axios.get(`${process.env.VUE_APP_API_BASE_URL}/events/${encodeURIComponent(this.categoryName)}/`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        })
        .then(response => {
          console.log('task data: ', response.data);
          this.tasks = response.data;
          this.fullTaskData = response.data;
        })
        .catch(error => {
          console.error('Error fetching tasks:', error);
          alert('Failed to fetch tasks. Please try again.');
        });
      } else {
        console.error('No access token found');
        alert('No access token found. Please log in.');
      }
    },
    fetchDecisionMakers() {
      const token = localStorage.getItem('access_token');
      if (token) {
        axios.get(`${process.env.VUE_APP_API_BASE_URL}/decision-makers/${this.proposalId}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        })
        .then(response => {
          this.decisionMakers = response.data.decision_makers;
          if (this.decisionMakers.length > 0) {
            if (this.selectedTask && this.selectedTask.decision_maker_id) {
              this.selectedDecisionMakerId = this.selectedTask.decision_maker_id;
            } else {
              this.selectedDecisionMakerId = '';
            }
            this.updateDecisionMakerDetails();
          }
        })
        .catch(error => {
          console.error('Error fetching decision makers:', error);
          alert('Failed to fetch decision makers. Please try again.');
        });
      } else {
        console.error('No access token found');
        alert('No access token found. Please log in.');
      }
    },
    updateDecisionMakerDetails() {
      const selectedDecisionMaker = this.decisionMakers.find(dm => dm.id === this.selectedDecisionMakerId);
      if (selectedDecisionMaker) {
        const firstName = selectedDecisionMaker.first_name || '';
        const lastName = selectedDecisionMaker.last_name || '';
        this.selectedDecisionMakerName = [firstName, lastName].filter(Boolean).join(' ');
      } else {
        this.selectedDecisionMakerName = '';
      }
    },
    closeModal() {
      this.$emit('close');
      this.resetModal();
    },
    proceedToTaskSelection() {
      this.step = 2;
    },
    selectTask(task) {
      this.selectedTask = task;
      this.selectedDecisionMakerId = task.decision_maker_id || ''; // Set decision maker ID or empty string
      this.updateDecisionMakerDetails();
      this.step = 2;
    },
    goBackToTaskSelection() {
      this.step = 1;
      this.selectedTask = null;
      this.selectedTaskDate = this.defaultDate;
    },
    goBackToTaskDetails() {
      this.step = 2;
    },
    saveTaskDetails() {
  // Check if the required fields are present
  if (!this.selectedTask || !this.selectedTaskDate || !this.selectedDecisionMakerId) {
    alert('Please ensure all task details (recipient, date, etc.) are filled in.');
    return;
  }

  const token = localStorage.getItem('access_token');
  const salesObjectId = this.selectedTask.salesObjectId; // Assuming `salesObjectId` is the correct ID

  // Prepare the request data with updated task details
  const requestData = {
    sales_object_id: this.selectedTask.salesObjectId,
    questionnaire_session_id: parseInt(this.proposalId, 10),
    scheduled_date: this.selectedTaskDate,
    decision_maker_id: this.selectedDecisionMakerId,
  };

  console.log('requestData:', requestData);

  // Make the PUT request to update the task
  axios.put(`${process.env.VUE_APP_API_BASE_URL}/schedule/${salesObjectId}/edit`, requestData, {
    headers: {
      'Authorization': `Bearer ${token}`,
    },
  })
  .then(response => {
    console.log('Task details updated successfully:', response.data);
    alert('Task details updated successfully.');
    this.$emit('refreshTasks'); // Trigger task refresh if needed
    this.closeModal(); // Close the modal after saving
  })
  .catch(error => {
    console.error('Error updating task details:', error);
    alert('Failed to update task details. Please try again.');
  });
},
    addTaskToBoard() {
      if (this.step === 5) {
        // Save content if on the preview step
        this.saveGeneratedContent();
      } else {
        if (this.selectedTask && this.selectedTaskDate) {
          this.addTaskToServer(this.selectedTask, this.selectedTaskDate, this.selectedDecisionMakerId)
            .then(() => {
              this.$emit('refreshTasks');
              this.closeModal();
            })
            .catch(error => {
              console.error('Error adding task:', error);
              alert('Failed to add task. Please try again.');
            });
        } else {
          alert('Please select a date.');
        }
      }
    },
    handleSaveOrAdd() {
    // If on the document preview step (step 5), navigate to the email preview
    if (this.step === 5 && (this.previewType === 'pdf' || this.previewType === 'docx')) {
      if (this.selectedTask.salesObjectId) {
        this.previewType="email";
        this.fetchGeneratedContent(this.selectedTask.salesObjectId);

      }

      this.step = 6; // Go to email preview step
    } 
    // If on the email preview step (step 6), save the email template content
    else if (this.step === 5 || this.step === 6) {
      this.saveGeneratedContent(); // Use your save function for email templates
    } 
    // If on the edit task step or adding a task
    else if (this.modalType === 'edit-task') {
      this.saveTaskDetails(); // Call the save function for tasks
    } else {
      this.addTaskToBoard(); // Handle the "Add" case for tasks
    }
  },
    confirmScheduledSalesObject() {
  const token = localStorage.getItem('access_token');
  const salesObjectId = this.selectedTask.salesObjectId;

  // Send PUT request to confirm the scheduled sales object
  axios.put(`${process.env.VUE_APP_API_BASE_URL}/schedule/${salesObjectId}/confirm`, {
    confirmed: true  // Assuming you want to set it to true, adjust if needed
  }, {
    headers: {
      'Authorization': `Bearer ${token}`,
    }
  })
  .then(() => {
    console.log('Scheduled sales object confirmed successfully.');
    //alert('Scheduled sales object has been confirmed.');
    this.$emit('refreshTasks');
    this.closeModal();
  })
  .catch(error => {
    console.error('Error confirming scheduled sales object:', error);
    alert('Failed to confirm scheduled sales object. Please try again.');
  });
},
    deleteScheduledSalesObject() {
      if (this.selectedTask && this.selectedTask.salesObjectId) {
        const salesObjectId = this.selectedTask.salesObjectId;
        const token = localStorage.getItem('access_token');
        
        axios.delete(`${process.env.VUE_APP_API_BASE_URL}/schedule`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
          data: {
            sales_object_id: salesObjectId,
          }
        })
        .then(response => {
          console.log('Task deleted successfully.');
          alert(response.data.message);  // Show success message
          this.$emit('refreshTasks');  // Refresh the tasks
          this.closeModal();  // Close the modal
        })
        .catch(error => {
          console.error('Error deleting task:', error);
          alert('Failed to delete task. Please try again.');  // Show error message
        });
      } else {
        alert('No task selected for deletion.');
      }
    },
    addTaskToServer(task, date, decisionMakerId) {
      const token = localStorage.getItem('access_token');
      const requestData = {
        sales_object_id: task.pivot.sales_object_id,
        scheduled_date: date,
        questionnaire_session_id: this.proposalId,
        category_name: this.categoryName,
        decision_maker_id: decisionMakerId
      };

      return axios.post(`${process.env.VUE_APP_API_BASE_URL}/schedule`, requestData, {
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      })
      .then(response => response.data)
      .catch(error => {
        console.error('Error adding task:', error);
        throw error;
      });
    },
    updateConfirmedStatus(confirmed) {
      const salesObjectId = this.selectedTask.salesObjectId;
      const token = localStorage.getItem('access_token');
      axios.put(`${process.env.VUE_APP_API_BASE_URL}/schedule/${salesObjectId}/confirm`, {
        confirmed: confirmed
      }, {
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      })
      .then(() => {
        console.log('Task confirmation status updated successfully.');
        this.$emit('refreshTasks');
      })
      .catch(error => {
        console.error('Error updating task confirmation status:', error);
        this.selectedTask.confirmed = !confirmed;
      });
    },
    onIframeLoad() {
    // Called when the iframe has finished loading its content
    this.loading = false;
  },

    showTaskPreview(taskId, content, previousStep) {
      console.log("Content: ", content);
      this.loading=true;

      // Set default content if not provided
      if (!content) {
        content = "Loading...";  // Default content to prevent undefined issues
      }

      // Save current state before preview
      this.saveState();

      if (this.selectedTask && this.selectedTask.type === 'email_template') {
        this.previewType = 'email';
        this.fetchGeneratedContent(taskId);
      } else if (this.selectedTask && this.selectedTask.download_link) {

        console.log('selectedTask: ', this.selectedTask);

        // Use the ID of the ScheduledSalesObject to create the proxy URL
        const scheduledSalesObjectId = this.selectedTask.salesObjectId; // Assuming salesObjectId is the ID of ScheduledSalesObject
        const proxyUrl = `${process.env.VUE_APP_API_BASE_URL}/proxy-file/${scheduledSalesObjectId}`;

        const downloadLink = this.selectedTask.download_link.toLowerCase();
        //this.fetchGeneratedContent(taskId);

        console.log("downloadLink: ", downloadLink);
        

        if (this.isPdfFile(downloadLink)) {
          this.previewType = 'pdf';
          this.previewContent = proxyUrl;
        } else if (this.selectedTask.type === 'docx_template') {
          this.previewType = 'pdf';
          this.previewContent = proxyUrl;
        } else if (this.isDocxFile(downloadLink)) {
          this.previewType = 'docx';
          this.previewContent = proxyUrl;
        } else {
          this.previewType = 'default';
          this.previewContent = content;
        }
      } else {
        this.previewType = 'default';  // Default preview type
        this.subject = 'Default Subject'; // Set a default subject for non-email tasks
        this.previewContent = content;
      }

      // Set previous step and navigate to preview
      this.previousStep = previousStep;
      this.step = 5; // Set step to preview
    },
    fetchGeneratedContent(taskId) {
      const token = localStorage.getItem('access_token');
      axios.get(`${process.env.VUE_APP_API_BASE_URL}/email-template/${taskId}/generate`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      })
      .then(response => {
        if (response.data && response.data.generated_text_payload) {
          const content = response.data.generated_text_payload;
          console.log("content: ", content);
          const lines = content.split('\n');
          this.subject = lines[0].replace(/^Subject:\s*/, '') || 'No Subject';  // Extract the subject or default
          this.previewContent = lines.slice(1).join('\n') || 'No Content'; // Ensure content is always set

          this.$emit('input', this.previewContent);  // Emit an event to update the content
          console.log("previewContent: ", this.previewContent);
        } else {
          console.error('Response data is missing generated_text_payload:', response.data);
          this.subject = 'Error: No data';
          this.previewContent = 'Error loading preview content.';
        }
      })
      .catch(error => {
        console.error('Error fetching generated content:', error);
        alert('Failed to fetch generated content. Please try again.');
        this.subject = 'Error: Fetch Failed';
        this.previewContent = 'Error loading preview content. Please try again later.';
      });
    },
    saveGeneratedContent() {
      const token = localStorage.getItem('access_token');

      // Construct the payload with subject and body
      const requestData = {
        sales_object_id: this.selectedTask.salesObjectId,
        generated_text_payload: `Subject: ${this.subject}\n${this.previewContent}`
      };

      // Send POST request to save the email template
      axios.post(`${process.env.VUE_APP_API_BASE_URL}/email-template/save`, requestData, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      })
      .then(() => {
        console.log('Generated content saved successfully.');
        this.$emit('refreshTasks');
        this.closeModal();
      })
      .catch(error => {
        console.error('Error saving generated content:', error);
        alert('Failed to save generated content. Please try again.');
      });
    },
    saveState() {
      // Save all relevant state variables for restoration
      this.savedState = {
        step: this.step,
        selectedTask: this.selectedTask,
        selectedTaskDate: this.selectedTaskDate,
        selectedDecisionMakerId: this.selectedDecisionMakerId,
        selectedDecisionMakerName: this.selectedDecisionMakerName,
        localModalType: this.localModalType,
        decisionMakers: [...this.decisionMakers],
        previewContent: this.previewContent,
        previewType: this.previewType,
        subject: this.subject,  // Save the subject state
        previousStep: this.previousStep,
      };
    },
    restoreState() {
      // Restore all relevant state variables from saved state
      this.step = this.savedState.step;
      this.selectedTask = this.savedState.selectedTask;
      this.selectedTaskDate = this.savedState.selectedTaskDate;
      this.selectedDecisionMakerId = this.savedState.selectedDecisionMakerId;
      this.selectedDecisionMakerName = this.savedState.selectedDecisionMakerName;
      this.localModalType = this.savedState.localModalType;
      this.decisionMakers = [...this.savedState.decisionMakers];
      this.previewContent = this.savedState.previewContent;
      this.previewType = this.savedState.previewType;
      this.subject = this.savedState.subject;  // Restore the subject state
      this.previousStep = this.savedState.previousStep;
    },
    goBackToPreviousStep() {
      if (this.step === 5 || this.step ===6) {
        // If currently in the preview step, restore the previous step
        this.step = this.previousStep || 1; // Restore to previous step or default to 1
        
        // Restore the appropriate modal state
        if (this.localModalType === 'edit-task') {
          this.step = 1; // Go back to the edit task screen if it's an edit-task type
        } else if (this.localModalType === 'task') {
          this.step = 2; // Go back to task selection if coming from a task preview
        }

      } else if (this.step === 1) {
        // If on the first step, close the modal
        this.closeModal();
      } else {
        // Restore the saved state if available
        if (this.savedState) {
          this.restoreState();
          this.savedState = null;  // Clear saved state after restoration
        } else {
          // Fallback to closing the modal if there's no saved state
          this.closeModal();
        }
      }
    },
    openProposal(proposalId) {
      this.$router.push({
        name: 'Account Page',
        params: { accountId: proposalId }
      });
      this.closeModal();
    },
    resetModal() {
      this.step = 1;
      this.selectedTask = null;
      this.selectedTaskDate = this.defaultDate;
      this.previewContent = '';
      this.subject = '';  // Reset subject
      this.previewType = 'email';  // Default to email preview
      this.previousStep = null;
      this.savedState = null;  // Clear saved state on reset
    },
    showDatePicker(datePickerId) {
      if (datePickerId === 'task-date-picker') {
        this.step = 4;
      } else if (datePickerId === 'task-date-edit-picker') {
        this.step = 3;
      }
    },
    handleDateSelect(date) {
      this.selectedTaskDate = date;
      if (this.modalType === 'edit-task') {
        this.step = 1;
      } else if (this.modalType === 'task') {
        this.goBackToTaskDetails();
      }
    },
    deleteTask() {
      if (this.selectedTask && this.selectedTask.salesObjectId) {
        const salesObjectId = this.selectedTask.salesObjectId;
        const token = localStorage.getItem('access_token');
        axios.delete(`${process.env.VUE_APP_API_BASE_URL}/schedule`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
          data: {
            sales_object_id: salesObjectId,
          }
        })
        .then(() => {
          this.$emit('refreshTasks');
          this.closeModal();
        })
        .catch(error => {
          console.error('Error deleting task:', error);
          alert('Failed to delete task. Please try again.');
          this.closeModal();
        });
      } else {
        this.closeModal();
      }
    }
  }
};
</script>

<style scoped>
.modal-backdrop {
  position: absolute;
  z-index: 1050;
  background-color: rgba(255, 255, 255, 0.95);
  padding: 15px;
  transform-origin: center top;
  opacity: 1;
  pointer-events: auto;
  width: 300px;
  height: 100%;
}

.modal-content {
  width: 100%;
  height: 100%;
  border-radius: 0.75rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
  border: 1px solid #ddd;
  padding: 15px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.fixed-header {
  flex-shrink: 0;
}

.modal-scrollable-content {
  flex: 1;
  max-height: 100%;
  overflow-y: auto;
  padding-right: 15px;
}

.fixed-footer {
  flex-shrink: 0;
}

.modal-scrollable-content::-webkit-scrollbar {
  width: 8px;
}

.modal-scrollable-content::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 4px;
}

.modal-scrollable-content::-webkit-scrollbar-thumb:hover {
  background-color: #bbb;
}

.close-button {
  position: absolute;
  top: -6px;
  right: 0px;
  background: none;
  border: none;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  color: #000;
  transition: color 0.2s ease;
}

.close-button:hover {
  color: #ff0000;
}

.event-list,
.task-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.event-list li,
.task-list li {
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.task-item {
  background-color: #f8f9fa;
  padding: 10px;
  border-radius: 0.375rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.icon-assign,
.icon-preview {
  cursor: pointer;
  font-size: 18px;
  color: #007bff;
  transition: color 0.2s ease;
  margin-left: 10px;
}

.icon-assign:hover,
.icon-preview:hover {
  color: #0056b3;
}

.icon-preview {
  font-size: 14px;
  margin-left: 4px;
}

.event-title {
  flex: 1;
  text-align: left;
}

.event-link,
.task-link {
  margin-left: 10px;
  color: #007bff;
  text-decoration: none;
  flex-shrink: 0;
  transition: color 0.2s ease;
}

.event-link:hover,
.task-link:hover {
  text-decoration: underline;
}

.object-info {
  margin-bottom: 15px;
}

/* Loading Spinner Container */
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
}

/* Spinner Styling */
.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1); /* Light outer border */
  border-left-color: #007bff; /* Blue spinner */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite; /* Spinning animation */
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Loading Text */
.loading-container p {
  margin-top: 10px;
  font-size: 16px;
  color: #333;
}

</style>
